import { template as template_d86a03ed341544d29cbed1f7d5ed75c8 } from "@ember/template-compiler";
const FKLabel = template_d86a03ed341544d29cbed1f7d5ed75c8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
